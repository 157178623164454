<template>
  <div id="page">
    <router-view/>
  </div>
</template>

<script>
  export default {
    name: 'App',
    data(){
      return {
        loading:true,
      }
    },
    created() {
      this.$store.dispatch('getUserInfo')
    },
  }
</script>
<style>
  #app {
    position: relative;
  }
</style>
