/**
 * Usage :  zoom fix for higher DPR screens
 * */

let applyZoomFix = (navigator.appVersion.indexOf('Win') !== -1 || navigator.platform.indexOf('Mac') !== -1) && 'zoom' in document.documentElement.style && window.devicePixelRatio > 1;
let zoomFixRatio;
if (applyZoomFix) {
  if (window.devicePixelRatio < 2) {
    zoomFixRatio = window.devicePixelRatio;
  } else {
    switch (screen.width) {
      case 1440:
        zoomFixRatio = 1.25;
        break;
      case 1280:
        zoomFixRatio = 1.5;
        break;
      default:
        zoomFixRatio = 1;
    }
  }
}
if (applyZoomFix) {
  document.write(`
  <style>
  	body { zoom:${1/zoomFixRatio}; height: calc(100vh * ${zoomFixRatio}) }
		.login-register { height: calc(100vh * ${zoomFixRatio}) }
		.page-header + .container { min-height: calc(100vh * ${zoomFixRatio} - 509px) }
	</style>
  `);
}
