export default {
  titleCase(str){
    str = str || ""
    return str.toLowerCase().replace(/^\w|\s\w/g,w=>w.toUpperCase())
  },
  momentTimeFormat(item,format="MMM DD,YYYY") {
    // let time = item.lastModifiedAt>item.publishedAt?item.lastModifiedAt:item.publishedAt
    let time = item.publishedAt
    let timeStr=this.$moment(time).tz("America/New_York").format(format);
    let timeDif = this.$moment().format('x') - this.$moment(this.$moment(time).toDate()).format('x')
    if(timeDif < 1000*60*60*24*1){
      const fromNowStr=this.$moment(time).fromNow(true);
      timeStr=fromNowStr+" ago"
    }
    return timeStr
  },
  momentTimeFormatAdmin(item,format="MMM DD,YYYY") {
    // let time = item.lastModifiedAt>item.publishedAt?item.lastModifiedAt:item.publishedAt
    let time = item.publishedAt
    let timeStr=this.$moment(time).tz("America/New_York").format(format);
    let timeDif = this.$moment().format('x') - this.$moment(this.$moment(time).toDate()).format('x')
    if(timeDif < (1000*60*60*1-1000)){
      const fromNowStr=this.$moment(time).fromNow(true);
      timeStr=fromNowStr+" ago"
    }
    return timeStr
  },
  momentFormat(date,format="MMM DD,YYYY") {
    // let time = item.lastModifiedAt>item.publishedAt?item.lastModifiedAt:item.publishedAt
    return this.$moment(date).tz("America/New_York").format(format);
  },
  removeHtmlTag(html=''){
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  },
  briefText(html=''){
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    let $textEl = tmp.querySelector('li') || tmp.querySelector('p') || tmp.querySelector('div') || tmp
    return $textEl.textContent || $textEl.innerText || "";
  },
  parseBrief(html=''){
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    let liElList = tmp.querySelectorAll('li') || []
    let finallyStr = ''
    if(liElList.length>0){
      liElList.forEach((item,idx)=>{
        finallyStr= finallyStr+item.innerText + (liElList.length-1 !== idx?'<br/>':'')
      })
    }else {
      finallyStr = tmp.innerText
    }
    return finallyStr
  },
  checkEmail(rule, value, callback) {
    let emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (emailReg.test(value) || value == '') {
      return callback();
    } else {
      callback(new Error("Please enter the correct email address."));
    }
  },
  moneyFormat(num){
    let numPrefix = ''
    let numArr = ''
    let numDist = ''
    let smallNum = String(num*1).split('.')[1]
    let smallNumStr = smallNum?`.${smallNum}`:''
    let numStr = String(num*1).split('.')[0]
    // 处理负数情况
    if (num < 0) {
      numPrefix = '-'
      numArr = numStr.slice(1).split('').reverse()
    } else {
      numArr = numStr.split('').reverse()
    }
    for (let i = 0; i < numArr.length; i++) {
      numDist += numArr[i]
      if ((i + 1) % 3 === 0 && (i + 1) < numArr.length) {
        numDist += ','
      }
    }
    return numPrefix + numDist.split('').reverse().join('')+smallNumStr
  },
  specialMoney(num=0){
    let n = (num/100).toFixed(2).toLocaleString()
    return n
  },
}
