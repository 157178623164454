import {
  createRouter,
  // createWebHashHistory,
  createWebHistory
} from 'vue-router'
import store from '../store'
import Cookies from 'js-cookie'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect:'/landing',
    component: () => import(/* webpackChunkName: "front" */ '../layout/layout.vue'),
    children:[
      {
        path: '/front',
        name: 'front',
        component: () => import(/* webpackChunkName: "front" */ '../views/front/index.vue'),
        meta:{
          title:'NAVLIN DAILY',
        }
      },
      {
        path: '/search',
        name: 'search',
        component: () => import(/* webpackChunkName: "search" */ '../views/search.vue'),
        meta:{
          title:'Search | NAVLIN DAILY',
        }
      },
      {
        path: '/keyword/:name?',
        name: 'keyword',
        component: () => import(/* webpackChunkName: "keyword" */ '../views/keyword.vue'),
        meta:{
          title:'Keyword | NAVLIN DAILY',
        }
      },
      {
        path: '/newsletterReport',
        name: 'newsletterReport',
        component: () => import(/* webpackChunkName: "newsletterReport" */ '../views/newsletterReport.vue'),
        meta:{
          title:'Monthly Newsletters & Reports | NAVLIN DAILY',
        }
      },
      {
        path: '/newsletterDetail/:id',
        name: 'newsletterDetail',
        component: () => import(/* webpackChunkName: "newsletterDetail" */ '../views/newsletterDetail.vue'),
        meta:{
          title:'Monthly Newsletters & Reports | NAVLIN DAILY',
        }
      },
      {
        path: '/article/:id/:alias?',
        name: 'article',
        component: () => import(/* webpackChunkName: "article" */ '../views/article/article.vue')
      },
      {
        path: '/category/:type/:name?',  // type: topic,author,articleType
        name: 'category',
        component: () => import(/* webpackChunkName: "article" */ '../views/category.vue')
      },
      {
        path: '/approvals',
        name: 'approvals',
        component: () => import(/* webpackChunkName: "approvals" */ '../views/approvals.vue'),
        meta:{
          title:'Approvals | NAVLIN DAILY',
        }
      },
      {
        path: '/hta',
        name: 'hta',
        component: () => import(/* webpackChunkName: "decision" */ '../views/hta.vue'),
        meta:{
          title:'HTA | NAVLIN DAILY',
        }
      },
      {
        path: '/bookmarks/:type?/:key?',
        name: 'bookmarks',
        component: () => import(/* webpackChunkName: "bookmarks" */ '../views/bookmarks.vue'),
        meta:{
          title:'Bookmarks | NAVLIN DAILY',
        }
      },
      {
        path: '/setting',
        redirect:'/setting/account',
        name: 'setting',
        component: () => import(/* webpackChunkName: "setting" */ '../views/setting/index.vue'),
        children:[
          {
            path: 'account',
            name: 'account',
            component: () => import(/* webpackChunkName: "setting" */ '../views/setting/account.vue'),
            meta:{
              title:'Settings - User Profile | NAVLIN DAILY',
              desc:'Account information',
            }
          },
          {
            path: 'password',
            name: 'password',
            component: () => import(/* webpackChunkName: "setting" */ '../views/setting/password.vue'),
            meta:{
              title:'Settings - User Profile | NAVLIN DAILY',
              desc:'Password details',
            }
          },
          {
            path: 'notification',
            name: 'notification',
            component: () => import(/* webpackChunkName: "setting" */ '../views/setting/notification.vue'),
            meta:{
              title:'Settings - Notification Preferences | NAVLIN DAILY',
              desc:'Notification Preferences',
            }
          },
          {
            path: 'billing',
            name: 'billing',
            component: () => import(/* webpackChunkName: "setting" */ '../views/setting/billing.vue'),
            meta:{
              title:'Settings - Subscription Details | NAVLIN DAILY',
              desc:'Subscription Details',
            }
          },
          {
            path: 'access',
            name: 'access',
            component: () => import(/* webpackChunkName: "setting" */ '../views/setting/access.vue'),
            meta:{
              title:'Settings - Team Access | NAVLIN DAILY',
              desc:'Team Access & Management',
            }
          },
        ],
      },
      {
        path: '/blank',
        name: 'blank',
        component: () => import(/* webpackChunkName: "blank" */ '../views/blank.vue'),
        meta:{
          title:'NAVLIN DAILY',
        }
      },
    ],
  },
  {
    path: '/admin',
    name: 'admin',
    redirect:'/admin/dashboard',
    component: () => import(/* webpackChunkName: "admin" */ '../layout/layout.vue'),
    children:[
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../admin/dashboard.vue'),
        meta:{
          title:'Admin',
          subTitle:'Dashboard',
          titleUrl:'/admin',
        },
      },
      {
        path: 'news',
        name: 'news',
        component: () => import(/* webpackChunkName: "newsadmin" */ '../admin/news/index.vue'),
        meta:{
          title:'News',
          subTitle:'News Management',
          titleUrl:'/admin/news',
        },
        children: [
        ]
      },
      {
        path: 'article/:id/:alias?', // for admin view same logic with article just different api
        name: 'adminArticle',
        component: () => import(/* webpackChunkName: "article" */ '../views/article/article.vue')
      },
      {
        path: 'news/edit/:id?',
        name: 'newsEdit',
        component: () => import(/* webpackChunkName: "newsEdit" */ '../admin/news/edit.vue'),
        meta:{
          title:'News',
          subTitle:'Create News',
          titleUrl:'/admin/news',
        },
      },
      {
        path: 'usage',
        name: 'usage',
        component: () => import(/* webpackChunkName: "Usage" */ '../admin/usage/index.vue'),
        meta:{
          title:'Usage',
          subTitle:'Usage',
          titleUrl:'/admin/usage',
        },
        children: [
        ]
      },
      // users section
      {
        path: 'user',
        name: 'user',
        component: () => import(/* webpackChunkName: "useradmin" */ '../admin/user/index.vue'),
        meta:{
          title:'User',
          subTitle:'User Management',
          titleUrl:'/admin/user',
        },
        children: [
        ]
      },
      {
        path: 'user/team/:id?',
        name: 'team',
        component: () => import(/* webpackChunkName: "teamadmin" */ '../admin/user/info.vue'),
        meta:{
          title:'Team',
          subTitle:'Account Details (Team)',
          titleUrl:'/admin/user',
        },
      },
      {
        path: 'user/individual/:id?',
        name: 'individual',
        component: () => import(/* webpackChunkName: "individualadmin" */ '../admin/user/info.vue'),
        meta:{
          title:'Individual',
          subTitle:'Account Details (Individual)',
          titleUrl:'/admin/user',
        },
      },
      {
        path: 'user/modify/:id?',
        name: 'userModify',
        component: () => import(/* webpackChunkName: "userModifyadmin" */ '../admin/user/modify.vue'),
        meta:{
          title:'Modify User',
          subTitle:'Account Details',
          titleUrl:'/admin/user',
        },
      },
      {
        path: 'newsletter',
        name: 'newsletter',
        component: () => import(/* webpackChunkName: "newsletteradmin" */ '../admin/newsletter/index.vue'),
        meta:{
          title:'Admin Newsletter',
          subTitle:'Admin Newsletter',
          titleUrl:'/admin/newsletter',
        },
      },
      {
        path: 'testTool',
        name: 'testTool',
        component: () => import(/* webpackChunkName: "testTool" */ '../admin/testTool.vue'),
        meta:{
          title:'Admin testTool',
          subTitle:'Admin testTool',
          titleUrl:'/admin/',
        },
      },
      {
        path: 'ads',
        name: 'ads',
        component: () => import(/* webpackChunkName: "ads" */ '../admin/ads'),
        meta:{
          title:'Ads',
          subTitle:'Admin Ads',
          titleUrl:'/admin/ads',
        }
      },
      {
        path: 'ads/modify/:id?',
        name: 'adsModify',
        component: () => import(/* webpackChunkName: "ads" */ '../admin/ads/modify.vue'),
        meta:{
          title:'Modify ads',
          subTitle:'Modify ads',
          titleUrl:'/admin/ads',
        },
      },
      // without sidebar
      {
        path: '/subscribe',
        name: 'subscribe',
        component: () => import(/* webpackChunkName: "landing" */ '../views/subscribe.vue'),
        meta:{
          title:'Subscription | NAVLIN DAILY',
        }
      },
      {
        path: '/signingUp',
        name: 'signingUp',
        component: () => import(/* webpackChunkName: "signingUp" */ '../views/account/signingUp.vue')
      },
    ],
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/account/register.vue')
  },
  {
    path: '/trial',
    name: 'trial',
    component: () => import(/* webpackChunkName: "trial" */ '../views/account/trial.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/account/login.vue'),
    meta:{
      title:'Login | NAVLIN DAILY',
    }
  },
  {
    path: '/enterVerificationCode/:next?',
    name: 'enterVerificationCode',
    component: () => import(/* webpackChunkName: "enterVerificationCode" */ '../views/account/enterVerificationCode.vue'),
    meta:{
      title:'Check Your Inbox | NAVLIN DAILY',
    }
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '../views/account/forgotPassword.vue'),
    meta:{
      title:'Forgot your password? | NAVLIN DAILY',
    }
  },
  {
    path: '/updatePassword',
    name: 'updatePassword',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '../views/account/updatePassword.vue'),
    meta:{
      title:'Update Password | NAVLIN DAILY',
    }
  },
  {
    path: '/checkInbox',
    name: 'checkInbox',
    component: () => import(/* webpackChunkName: "checkInbox" */ '../views/account/checkInbox.vue'),
    meta:{
      title:'Check your inbox | NAVLIN DAILY',
    }
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import(/* webpackChunkName: "contactUs" */ '../views/account/contactUs.vue')
  },
  {
    path: '/contactUsThankYou',
    name: 'contactUsThankYou',
    component: () => import(/* webpackChunkName: "contactUsThankYou" */ '../views/account/contactUsThankYou.vue')
  },
  {
    path: '/landing',
    name: 'landing',
    component: () => import(/* webpackChunkName: "landing" */ '../views/landing/landing.vue')
  },
  {
    path: '/termsAndConditions',
    name: 'termsAndConditions',
    component: () => import(/* webpackChunkName: "termsAndConditions" */ '../views/termsAndConditions.vue')
  },
  {
    path: '/newsletterThankYou',
    name: 'newsletterThankYou',
    component: () => import(/* webpackChunkName: "newsletterThankYou" */ '../views/newsletterThankYou.vue')
  },
  {
    path: '/scheduleMeeting',
    name: 'scheduleMeeting',
    component: () => import(/* webpackChunkName: "newsletterThankYou" */ '../views/scheduleMeeting.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "landing" */ '../views/test.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notfound" */ '../views/landing/landing.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return desired position
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
function setUid(){
  if(!Cookies.get('client-id') && !localStorage.getItem('client-id')){
    let id = uuidv4()
    Cookies.set('client-id',id)
    localStorage.setItem('client-id',id)
  }
}

router.beforeEach((to,from,next) => {
  // console.log('******',to);
  if(window.gtag){
    window.gtag('get', process.env.VUE_APP_GAID, 'client_id', (id) => {
      // do something with client_id
      if(id){
        Cookies.set('client-id',id)
        localStorage.setItem('client-id',id)
      }else {
        setUid()
      }
    })
  }else {
    setUid()
  }
  // set title
  if (to.meta.title) {//判断是否有标题
    document.title = to.meta.title
  }
  next()
})

router.afterEach((to)=>{
  for(let key in store.state.navStatus){
    if(Object.hasOwn(store.state.navStatus,key)){
      store.commit('setNavStatusVal',{
        key,
        val:false
      })
    }
  }
  console.log('to',to);
  try {
    window.amplitude.track('Page Viewed', {
      'Page Path':to.fullPath,
      'Page Title':to.params.alias || to.meta.title || to.name
    })
  }catch (e) {
    console.log(e);
  }
})

export default router
