import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';

import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import './scss/element-variables.scss';
import './scss/style.scss'
import '@fortawesome/fontawesome-free/css/all.css'
import baseData from "@/utils/baseData";
import http from '@/utils/http'
import Cookies from 'js-cookie'
import moment from 'moment'
import 'moment-timezone';

import Plugins from '@/utils/plugins'
import tools from '@/utils/tools'
import {postDataKeyMap} from '@/utils/mapData'

import cSelect from '@/components/cSelect'
import cDropdown from '@/components/cDropdown'
import loadMore from '@/components/loadMore'
import '@/utils/zoomFix'

window.Cookies = Cookies

const app = createApp(App)
app.use(store)
  .use(Plugins)
  .use(router)
  .use(ElementPlus, {
    // size: 'small', zIndex: 3000
  })
  .component('cSelect', cSelect)
  .component('cDropdown', cDropdown)
  .component('loadMore', loadMore)
  .mount('#app');

app.config.globalProperties.baseData = baseData
app.config.globalProperties.$http = http
app.config.globalProperties.$cookies = Cookies
app.config.globalProperties.$moment = moment
app.config.globalProperties.postDataKeyMap = postDataKeyMap
app.config.globalProperties.baseUrl = process.env.VUE_APP_PUBLIC_PATH == '/'?'':process.env.VUE_APP_PUBLIC_PATH
app.config.globalProperties.currentYear = moment().year()
Object.assign(app.config.globalProperties,tools)

window.$moment = moment
window.$router = router
window.$app = app

