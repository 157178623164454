<template>
  <div class="section-load-more">
    <p v-if="isLoading" class="gray text-center" style="margin-top: 12px;">Loading <i class="fas fa-spinner"></i></p>
    <p v-if="!hasMore && list.length !== 0 && showNoMore" class="gray text-center" style="margin-top: 12px;">No more</p>
    <p v-if="hasMore && !isLoading && list.length !== 0" @click="load"
       class="gray text-center pointer" style="margin-top: 12px;">More
      <i class="fas fa-angle-double-right" style="transform: rotate(90deg);"></i>
    </p>
    <el-empty v-if="!isLoading && list.length == 0" :image-size="200"></el-empty>
  </div>
</template>

<script>
  // <hasMore :list="list" :isLoading="isLoading" :hasMore="hasMore"></hasMore>
  export default {
    props:{
      hasMore:{
        type:Boolean,
      },
      isLoading:{
        type:Boolean,
      },
      showNoMore:{
        type:Boolean,
        default:true
      },
      list:{
        type:Array,
        default:()=>([])
      },
    },
    methods:{
      load(){
        this.$emit('load')
      }
    }
  }
</script>
