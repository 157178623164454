import axios from 'axios';
import qs from 'qs';
import Cookies from 'js-cookie'
import { ElMessage } from 'element-plus';

const Message = ElMessage

global.Cookies = Cookies
global.baseUrl = process.env.VUE_APP_PUBLIC_PATH == '/'?'':process.env.VUE_APP_PUBLIC_PATH
// global.baseWsHost = process.env.baseWsHost
global.wssBaseUrl = (process.env.NODE_ENV? process.env.VUE_APP_wssBaseUrl : window.location.host)+global.baseUrl
import router from '../router'

let http = axios.create({
  timeout: 45000,
  baseURL: global.baseUrl,
})

// let errorMsg = null
let errorRoute = false

http.interceptors.request.use(config => {
  config.headers['X-XSRF-TOKEN'] = Cookies.get('XSRF-TOKEN')
  config.headers['client-id'] = Cookies.get('client-id') || localStorage.getItem('client-id')
  // config.headers['Accept-Language'] = 'en-US,en;q=0.9,zh-CN;q=0.8,zh;q=0.7'
  return config
}, error => {
  return Promise.reject(error)
});

http.interceptors.response.use(response => {
  // errorMsg = null
  return response.data
}, error => {
  console.log('err', error.response);
  console.log('error.__CANCEL__', error);
  let errorStr = (error.response && (error.response.data.detail || error.response.data.title)) || Error(error).toString()
  if (!error.response && !error.__CANCEL__) {
    console.log(error);
    Message.error('Network Error')
  } else if (error.response && error.response.status == 401 && !errorRoute) {
    if (window.location.pathname.indexOf('/login') == -1) { // avoid repeated jumps
      errorRoute = true
      router.push('/login?from=' + window.location.pathname).then(() => {
        router.go(router.currentRoute)
      })
    } else {
      Message.error('Login failed.Please check your account and password')
    }
  } else if (error.response && (error.response.status == 403 && !errorRoute)) {
    errorRoute = true
    let from = window.location.pathname.indexOf('/login') == -1 ? window.location.pathname : ''
    router.push('/login?from=' + from).then(() => {
      router.go(router.currentRoute)
    })
  } else if (errorStr !== 'Error: Cancel') {
    // if(error.response.status == 403){
    //   Message.error('Access is denied')
    // }else
    console.log('*************', errorStr);
    Message({
      message: errorStr,
      type: 'error',
      customClass: 'msg-index'
    })
  }
  // errorMsg = true
  return Promise.reject(error)
});

http.$get = (url, data, config) => {
  if (data) {
    url += ('?' + qs.stringify(data, { allowDots: true }))
  }
  return http.get(url, config)
}
http.$post = (url, data, config) => {
  return http.post(url, qs.stringify(data), config)
}

export default http
