import { createStore } from 'vuex'
import http from "@/utils/http";

export default createStore({
  state: {
    userInfo:{},
    topicList:[],
    typeList:[],
    categoryList:[],
    authorList:[],
    isFree:false,
    navStatus:{
      showNav: false,
      showAdminNav:false,
      showUserNav:false,
      showMobileNav:false,
      showMobileUserNav:false,
      showMobileSearchResult:false,
    },
    switched:false,
  },
  mutations: {
    setUserInfo(state,val){
      state.userInfo = val
    },
    setVal(state, data) {
      state[data.key] = data.val
    },
    setNavStatusVal(state, data) {
      state.navStatus[data.key] = data.val
    },
  },
  actions: {
    getUserInfo({commit}){
      return http.get('/api/user/me').then(res=>{
        localStorage.setItem('X-XSRF-TOKEN',res.csrf.token)
        // if not login remove switched flag
        if(!res.user || !res.user.username){
          localStorage.removeItem('switched')
        }
        commit('setUserInfo',res.user.username?res.user:{})
        let configObj = {
          'cookie_domain': process.env.VUE_APP_wssBaseUrl,
          'cookie_expires': 30 * 60, // 30 min
        }
        if(res.user.username){
          configObj.user_id = res.user.id
        }
        window.gtag('config', process.env.VUE_APP_GAID, configObj);
      }).finally(()=>{
        this.loading = false
      })
    },
  },
  modules: {
  }
})
