export const publicationTypesArr = [
  {label:'PRICENTRIC', text:'Pricentric'},
  {label:'NAVLIN_DAILY', text:'NAVLIN DAILY'},
  {label:'FREE', text:'Free'},
]

export const publicationTypesMap = publicationTypesArr.reduce((obj,currentValue)=>{
  obj[currentValue.label] = currentValue.text
  return obj
},{})

export const articleTypesArr = [
  {label:'FEATURED', text:'Featured'},
  {label:'BREAKING_NEWS', text:'Breaking News'},
  {label:'EDITOR_CHOICE', text:'Editor Choice'},
]
export const articleTypesMap = articleTypesArr.reduce((obj,currentValue)=>{
  obj[currentValue.label] = currentValue.text
  return obj
},{})

export const postDataKeyMap = {
  topic:'topics',
  article_type:'articleType',
}
export const decisionMap = {
  'RECOMMENDED': 'RECOMMENDED',
  'NOT RECOMMENDED': 'NOT RECOMMENDED',
  'CONDITIONALLY RECOMMENDED': 'CONDITIONAL',
  'NO DECISION': 'NO OUTCOME',
  'Favorable':'RECOMMENDED',
  'Unfavorable':'NOT RECOMMENDED',
  'ADVICE': 'ADVICE'
}
export const decisionColorMap = {
  'RECOMMENDED': '#1eb412',
  'NOT RECOMMENDED': '#fc726d',
  'CONDITIONALLY RECOMMENDED': '#f0c710',
  'NO DECISION': '#435b6f',
  'Favorable':'RECOMMENDED',
  'Unfavorable':'NOT RECOMMENDED',
  'ADVICE': '#ff7f24'
}


export const planIntroMap = {
  'individual.quarterly': {
    title:'Quarterly',
    desc:'$18.40 per week. <br>Billed quarterly ($240)',
  },
  'individual.six_months': {
    title:'Half Year',
    titleHtml:'Half Year <small>Most popular</small><br>&emsp;',
    desc:'This subscription is billed half a year.',
  },
  'individual.annually': {
    title:'Annual Fee',
    desc:'Billed today, and ready <br> for immediate use!',
  },
  'team.of.3.annually': {
    title:'3 Users',
    desc:'Billed annually. Save $30!<br>&emsp;',
  },
  'team.of.5.annually': {
    title:'5 Users',
    titleHtml:'5 Users <small>Most popular</small>',
    desc:'Billed annually. Save $60!<br>&emsp;',
  },
  'team.of.10.annually': {
    title:'10 Users',
    desc:'Billed annually. Save $150!<br>&emsp;',
  },
}

export const metaDataKeyList = ['products','indications','therapeuticAreas','companies','regions','countries']
export const metaDataSingleKeyList = ['productGroup','indication','therapeuticArea','company']

export const categoryTitleMap = {
  'verification':'UPDATED',
}
export default {

}
