/**
 * 滚动加载指令
 * */

export default {
  install(app){
    app.directive('scroll', {
      created(el, binding) {
        el.loadEvent = function(){
          let isLoading = el.getAttribute('isLoading') === 'true'
          // console.log(isLoading);
          let scrollHeight = window.scrollY
          let bodyHeight = document.querySelector('body').scrollHeight
          let innerHeight = window.innerHeight
          let bottomHeight = bodyHeight-scrollHeight - innerHeight
          // console.log(bottomHeight<200);
          if(bottomHeight<200 && !isLoading){
            binding.value()
          }
        }
      },
      beforeMount(el){
        // default load data
        // binding.value()
        window.addEventListener ('scroll', el.loadEvent);
        // console.log('+++++++++',el.loadEvent);
      },
      beforeUnmount(el){
        window.removeEventListener ('scroll', el.loadEvent);
        // console.log('-------',el.loadEvent);
      },
    })

    app.directive('form-label', {
      mounted(el,binding) {
        let formEl = el.querySelector('input') || el.querySelector('textarea')
        el.labelActive = function(){
          el.querySelector('label').classList.add('shrinked')
          formEl.classList.add('no-outline')
        }
        el.labelDefault = function(){
          let inputVal = formEl.value
          if(!inputVal){
            el.querySelector('label').classList.remove('shrinked')
            formEl.classList.remove('no-outline')
          }
        }

        formEl.addEventListener('focus', el.labelActive);
        formEl.addEventListener('blur', el.labelDefault);
        formEl.addEventListener('change', (event)=>{
          if(event.target.value){
            el.labelActive()
          }else {
            el.labelDefault()
          }
        });
        console.log(binding.value);
        if(binding.value){
          el.querySelector('label').classList.add('shrinked')
          formEl.classList.add('no-outline')
          formEl.focus()
        }
      }
    })
  }
}