<template>
  <div class="c-select el-select"
       @blur="showOption = false">
    <div class="show-content">
<!--  should be v show  if dom is not exist close click event will cause some bug-->
      <span v-show="val.length > 0 && !showList"
            class="el-tag el-tag--info el-tag--small el-tag--light">
        {{val.length}} Selected <i class="el-tag__close icon-clear" @click="clearSelect"></i>
      </span>
      <span v-for="(item,idx) in val" :key="idx" v-show="val.length > 0 && showList"
            class="el-tag el-tag--info el-tag--small el-tag--light">
          {{item[labelKey] || item}} <i class="el-tag__close icon-clear" @click="deleteItem(idx)"></i>
        </span>
      <input type="text" @focus="showOption = true"
             @input="filterByKeyword" v-model="keyword"
             :placeholder="val.length>0?'':placeholder"
             class="el-select__input">
      <span class="el-input__suffix" @click="showOption = !showOption">
        <i class="el-icon-arrow-down"></i>
<!--        <i class="el-icon-arrow-up"></i>-->
      </span>
    </div>
    <div class="option-list el-dropdown__popper el-select-dropdown is-multiple el-popper is-light is-pure"
    v-if="showOption">
      <el-scrollbar ref="scroll" :max-height="270">
        <div class="list-box" v-infinite-scroll="onScroll" :infinite-scroll-immediate="false">
          <div class="item el-select-dropdown__item filter-select-option"
               @click="toggleItem(item)"
               :class="{'selected':isSelected(item)}"
               v-for="(item,idx) in curOpt" :key="idx">
            <span>{{item[labelKey] || item}}</span>
          </div>
        </div>
        <div class="gray text-center" v-if="curOpt.length == 0 || isLoading">
          {{isLoading?'Loading':'No data...'}}
        </div>
      </el-scrollbar>
      <div class="el-popper__arrow"></div>
    </div>
  </div>
</template>
<script>
  export default {
    props:{
      modelValue:{
        type:[String,Array],
        default:'',
      },
      options:{
        type:Array,
        default:()=>[],
      },
      placeholder:{
        type:String,
        default:'Select',
      },
      showList:{
        type:Boolean,
        default:false,
      },
      valueKey:{
        type:String,
        default:'id',
      },
      labelKey:{
        type:String,
        default:'name',
      },
      isLoading:{
        type:Boolean,
        default:false,
      },
      clearAfterSelect:{
        type:Boolean,
        default:false,
      },
    },
    data() {
      return {
        showOption:false,
        val:[],
        resultOpt:[],
        curOpt:[],
        keyword:'',
        page:0,
      }
    },
    watch:{
      options:{
        handler(val){
          this.resultOpt = val
        },
        deep: true,
      },
      resultOpt:{
        handler(val){
          this.setVal(val)
        },
        deep: true,
      },
      val:{
        handler(val){
          this.$emit('update:modelValue',val)
        },
        deep: true,
      },
      modelValue:{
        handler(val){
          this.val = val
        },
        deep: true,
        immediate: true,
      },
      showOption:{
        handler(val){
          if(val){
            this.$emit('showOption')
            document && document.addEventListener("click",this.docClick);
          }else {
            document && document.removeEventListener("click",this.docClick);
          }
        },
        immediate: true,
      },
    },
    created() {
      this.setVal(this.options)
    },
    mounted() {

    },
    methods: {
      toggleItem(item){
        let arr = this.val
        if(this.val[0] && this.val[0][this.valueKey]){
          arr = this.val.map(item=>item[this.valueKey])
        }
        let idx = arr.indexOf(item[this.valueKey] || item)
        if(idx == -1){
          this.val.push(item)
          if(this.clearAfterSelect){
            this.keyword = ''
            this.resultOpt = [...this.options]
          }
        }else {
          this.val.splice(idx,1)
        }
        this.$el.querySelector('input').focus()
      },
      clearSelect(){
        this.val = []
      },
      filterByKeyword(){
        if(this.keyword){
          this.showOption = true
          this.resultOpt = this.options.filter(item=>{
            // change fuzzy match to the key word start way
            let i = (item && item[this.labelKey])?item[this.labelKey] : (item || '')
            return i.toLocaleLowerCase().indexOf(this.keyword.toLocaleLowerCase()) === 0
          })
        }else {
          this.resultOpt = this.options
        }
      },
      onScroll(){
        let pageSize = 100
        let start = this.page*pageSize
        let tempArr = this.resultOpt.slice(start,start+pageSize)
        // console.log('this.curOpt',this.curOpt);
        // console.log('tempArr',tempArr);
        if(tempArr.length>0){
          let temp = this.curOpt.concat(tempArr)
          this.curOpt = [...temp]
          this.page += 1;
          this.$refs.scroll && this.$refs.scroll.update()
        }
      },
      setVal(val){
        if(val.length>100){
          this.curOpt = val.slice(0,100)
        }else {
          this.curOpt = val
        }
        this.page = 1;
      },
      deleteItem(idx){
        this.val.splice(idx, 1)
      },
      docClick(e){
        if(e.target !== this.$el && !this.$el.contains(e.target)){
          this.showOption = false
        }
      },
      isSelected(item){
        if(item[this.valueKey]){
          return this.val.map(i=>i[this.valueKey]).indexOf(item[this.valueKey]) !==-1
        }else {
          return this.val.indexOf(item) !==-1
        }
      },
    },
  }
</script>
