<template>
  <div class="c-dropdown">
    <span @click="toggle">
      <slot></slot>
    </span>
    <div class="dropdown-content" v-if="show">
      <slot name="dropdown"></slot>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        show:false,
      }
    },
    watch:{
      show:{
        handler(val){
          if(val){
            document && document.addEventListener("click",this.docClick);
          }else {
            document && document.removeEventListener("click",this.docClick);
          }
        },
        immediate: true,
      },
    },
    created() {

    },
    mounted() {
    },
    methods: {
      toggle(){
        this.show = !this.show;
      },
      hide(){
        this.show = false;
      },
      docClick(e){
        if(e.target !== this.$el && !this.$el.contains(e.target)){
          console.log('this.$el',this.$el);
          console.log(e.target);
          this.show = false
        }
      },
    },
  }
</script>
